<template>
	<div class="single-view tool-url-parser">
		<div class="tool-box">
			<h1>{{ ii('URL_PARSER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						type="text"
						v-model="app.query.url"
						ref="tf"
						prepend-icon="globe"
						:placeholder="ii('URL')"
						@change="refreshQuery"
					></s-text-field>
				</div>
				<div>
					<table v-if="parsed" class="result-tbl">
						<tbody>
						<tr>
							<td>{{ ii('PROTOCOL') }}:</td>
							<td>{{ parsed.protocol.replace(/:$/, '') }}</td>
						</tr>
						<tr>
							<td>{{ ii('HOST') }}:</td>
							<td>{{ parsed.hostname }}</td>
						</tr>
						<tr v-if="parsed.port">
							<td>{{ ii('PORT') }}:</td>
							<td>{{ parsed.port }}</td>
						</tr>
						<tr>
							<td>{{ ii('PATH') }}:</td>
							<td>{{ parsed.pathname }}</td>
						</tr>
						<tr>
							<td>{{ ii('PARAMS') }}:</td>
							<td>
								<table v-if="params && params.length" class="result-tbl">
									<tbody>
									<tr v-for="param in params">
										<td class="no-text-transform">{{ param.key }}</td>
										<td>=</td>
										<td class="param-val-cell">
											<template v-if="param.isUrl">
												<span class="link" @click="setUrl(param.val)">{{ param.val }}</span>
												<a
													:href="param.val"
													class="fa fa-external-link"
													target="_blank"
													rel="noreferrer noorigin"
												></a>
											</template>
											<span v-else>{{ param.val }}</span>
										</td>
									</tr>
									</tbody>
								</table>
							</td>
						</tr>
						<tr v-if="parsed.hash">
							<td>{{ ii('HASH') }}:</td>
							<td>{{ parsed.hash }}</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>
			</div>
		</div>
	</div>
</template>

<style lang="less">
.tool-url-parser {
	strong {
		text-transform: uppercase;
		font-weight: 500;
	}
}
</style>

<script>
export default {
	data() {
		return {
			parsed: null,
			params: null
		};
	},
	methods: {
		setUrl(url) {
			this.editQueryParams({url});
		},
		update() {
			let url = this.app.query.url;

			try {
				this.parsed = new URL(url.trim());
			} catch (err) {
				this.parsed = null;
				this.params = null;
				return;
			}
			let params = [];
			for (let [key, val] of this.parsed.searchParams.entries()) {
				let isUrl = false;
				try {
					new URL(val);
					isUrl = true;
				} catch (err) {}
				params.push({key, val, isUrl});
			}
			this.params = params;
		}
	},
	watch: {
		'app.query.url'() {
			this.update();
		}
	},
	mounted() {
		if (!this.app.isMob) {
			this.$refs.tf.focus();
		}
		this.update();
	}
}
</script>
